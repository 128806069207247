import { Injectable, signal } from '@angular/core';
import { IAuth, ILogin } from '../../interfaces/auth.interface';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { IGenericResponse } from '../../interfaces/generic.interface';
import { TokenService } from './token.service';
import { jwtDecode } from 'jwt-decode';
import { IToken } from '../../interfaces/token.interface';
import { Router } from '@angular/router';
import { CustomerService } from '../customer.service';
import { ICustomer } from '../../interfaces/customers.interface';
import { IUser } from '../../interfaces/users.interface';

const END_TOKEN_ROLE = 'claims/name';
const END_TOKEN_SID = 'claims/sid';

const ROLE_SYSTEM = 'SYSTEM';
const ROLE_FUNDEPAG = 'FUNDEPAG';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = `${environment.apiUrl}/auth`;
  handleErrorCustom = { handleErrorCustom: 'true' };

  private permissions: string[] = [];
  private role!: string;
  private userId!: string;

  userIsCustomer = signal<boolean>(true);
  userActive = signal<IUser | null>(null);
  firstAccess = signal<boolean>(false);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private router: Router,
    private customerService: CustomerService
  ) {
    if (this.isLoggedIn()) {
      this.initializeSettings();
      this.validateUserType();
    }
  }

  initializeSettings(): void {
    const token = this.tokenService.getToken();
    const decodedToken = jwtDecode(token) as IToken;

    this.setPermissions(decodedToken);
    this.decodeConfigurations(decodedToken);
    this.setCustomerActive(decodedToken);
    this.setLoggedInUser();
  }

  login(login: ILogin): Observable<IAuth> {
    return this.http
      .post<IAuth>(`${this.apiUrl}`, login, {
        headers: this.handleErrorCustom
      })
      .pipe(
        tap((res) => {
          this.firstAccess.set(res.firstAccess);

          if (res.token) {
            this.tokenService.setToken(res.token);
            this.initializeSettings();
          }
        })
      );
  }

  isLoggedIn(): boolean {
    const token = this.tokenService.getToken();

    if (!token) {
      return false;
    }

    return this.tokenService.jwtTokenValidation(token);
  }

  logout(): void {
    this.tokenService.removeToken();
    this.customerService.customerActive.set(null);
    this.router.navigate(['/']);
  }

  setPermissions(decodedToken: IToken): void {
    this.permissions = decodedToken?.Permission ?? [];
  }

  setCustomerActive(decodedToken: IToken): void {
    const customerId = decodedToken?.CompanyId;
    const customerName = decodedToken?.CompanyName;

    if (customerId) {
      this.customerService.customerActive.set({
        id: customerId,
        name: customerName
      } as ICustomer);
    }
  }

  setLoggedInUser(): void {
    this.userActive.set({
      id: this.userId,
      name: this.role
    } as IUser);
  }

  decodeConfigurations(decodedToken: IToken): void {
    Object.keys(decodedToken).forEach((key) => {
      if (key.endsWith(END_TOKEN_ROLE)) {
        this.role = decodedToken[key as keyof IToken] as string;
      } else if (key.endsWith(END_TOKEN_SID)) {
        this.userId = decodedToken[key as keyof IToken] as string;
      }
    });
  }

  setPassword(
    token: string,
    password: string
  ): Observable<IGenericResponse<boolean>> {
    return this.http.post<IGenericResponse<boolean>>(
      `${this.apiUrl}/setPassword`,
      {
        password,
        token
      }
    );
  }

  recoverPassword(email: string): Observable<IGenericResponse<boolean>> {
    return this.http.post<IGenericResponse<boolean>>(
      `${this.apiUrl}/createSetPasswordRequest`,
      {
        email
      }
    );
  }

  hasPermission(permission: string): boolean {
    if (!this.permissions.length) {
      return true;
    }

    return this.permissions.includes(permission);
  }

  validateUserType(): void {
    if (!this.customerService.customerActive()?.id) {
      this.userIsCustomer.set(false);
    } else {
      this.userIsCustomer.set(true);
    }
  }

  routeToPlatform(): void {
    this.validateUserType();

    if (!this.userIsCustomer()) {
      this.router.navigate(['/plataforma/gerenciamento-clientes']);
      return;
    }

    this.router.navigate(['/plataforma/home']);
  }
}
